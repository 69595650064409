<template>
  <div>
    <titulo-historico-escolar />

    <filtro-matricula-nome @resultado="getFiltro" />

    <tabela :items="itemsFiltro" @historico="obtemHistorico" />

    <paginacao
      :total="total"
      v-model="paginacao"
      @input="getMatriculas(filtro)"
    />

    <modal-historico
      :exibir="modais.historico"
      :aluno="modais.historicoDados"
      @fechar="closeModal('historico')"
    />
  </div>
</template>
<script>
// Utils & Auxs:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service';
import CurriculoEscolarService from '@/common/services/curriculo-escolar/curriculo-escolar.service';

// Components:
import FiltroMatriculaNome from '@/components/filtro/MatriculaNome';
import Tabela from './components/TabelaMatricula';
import ModalHistorico from './components/Modal';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloHistoricoEscolar from '@/views/academico/outras-instituicoes/historico-escolar/components/TituloHistoricoEscolar';

export default {
  components: {
    TituloHistoricoEscolar,
    FiltroMatriculaNome,
    Tabela,
    ModalHistorico,
    Paginacao,
  },
  mounted() {
    this.getMatriculas();
  },
  data() {
    return {
      items: [],
      itemsFiltro: [],
      total: 0,
      modais: {
        historico: false,
        historicoDados: {},
      },
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
    };
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getMatriculas(buscarPor) {
      this.filtro = buscarPor;
      this.$store.dispatch(START_LOADING);
      MatriculaService.listar(this.paginacao)
        .then(({ data }) => {
          this.items = data.itens;
          this.itemsFiltro = this.items;
          this.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    obtemHistorico(key, item) {
      this.$store.dispatch(START_LOADING);
      CurriculoEscolarService.obterPorMatriculaId(item.id)
        .then(({ data }) => {
          this.modais.historicoDados = data;
          this.openModal(key, item);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES FILTRO:
    getFiltro(resultadoFiltro) {
      this.itemsFiltro = [];
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];

        if (element.id == resultadoFiltro) {
          this.itemsFiltro.push(element);
        }
      }
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
