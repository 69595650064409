import ApiService from "@/common/api/api.service";

let apiBasePath = 'CurriculoEscolar'

const CurriculoEscolarService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir(historicoEscolarId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${historicoEscolarId}`)
        return data
    },

    // DISCIPLINAS:
    async adicionarDisciplina (disciplina) {
        let { data } = await ApiService.post(`${apiBasePath}/disciplina/adicionar`, disciplina)
        return data
    },
    async atualizarDisciplina (disciplina) {
        let { data } = await ApiService.put(`${apiBasePath}/disciplina/atualizar`, disciplina)
        return data
    },
    async excluirDisciplina (disciplina) {
        let { data } = await ApiService.delete(`${apiBasePath}/disciplina/remover?curriculoEscolarId=${disciplina.curriculoEscolarId}&curriculoEscolarDisciplinaId=${disciplina.id}`)
        return data
    },

    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    async obterPorMatriculaId(matriculaId){
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-matricula-id/${matriculaId}`)
        return data
    },

    async imprimirHistorico(matriculaId){
        let { data } = await ApiService.get(`${apiBasePath}/imprimir/historico-escolar-por-matricula-id/${matriculaId}`);
        return data;
    }

    
}

export default CurriculoEscolarService;