<template>
  <div class="row align-items-start">
    <div class="col-12 col-md-4 align-items-start">
      <input-select-search
        ref="disciplinaCurriculoId"
        :label="'Disciplina'"
        :options="disciplinas"
        v-model="disciplina.disciplinaCurriculoId"
        required
      />
    </div>
    <div class="col-12 col-md-2">
      <input-text
        ref="faltas"
        v-model="disciplina.faltas"
        :label="'Faltas'"
        type="number"
        required
      />
    </div>
    <div class="col-12 col-md-2">
      <input-text
        ref="cargaHoraria"
        v-model="disciplina.cargaHoraria"
        :label="'Carga Horária'"
        type="number"
        required
      />
    </div>
    <div class="col-12 col-md-2">
      <input-text
        ref="media"
        v-model="disciplina.media"
        :label="'Média'"
        type="number"
        required
      />
    </div>
    <div class="col-12 col-md-2 botao-acao-filtro align-items-start">
      <b-button
        variant="primary"
        @click="adicionar"
        class="mb-3 w-100"
      >
        {{ $t('GERAL.ADICIONAR') }}
      </b-button>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import { InputText, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    disciplinas: { type: Array, default: Array },
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  data() {
    return {
      disciplina: {},
    };
  },
  methods: {
    // EMITINDO DADO PARA TABELA(ela que faz o CRUD da disciplina):
    adicionar() {
      if (!this.validarFormulario()) return;
      this.$emit('adicionar', this.disciplina);
      this.disciplina = {};
    },
    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>

<style scoped>

</style>
