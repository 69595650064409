<template>
  <modal
    id="modalHistorico"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="lg"
    @fechar="fecharModal"
  >
    <template v-if="formularioHistorico">
      <form-historico :form="historico" @refs="getRefs" />
      <hr />
      <disciplinas :form="historico" />
    </template>
    <tabela
      v-if="!formularioHistorico"
      :items="historicos"
      @editar="abrirFormulario"
      @excluir="confirmarExclusao"
    />
    <template #modal-footer>
      <template v-if="formularioHistorico">
        <div class="d-flex w-100 justify-content-between">
          <b-button variant="secondary" @click="voltar">
            {{ $t('GERAL.CANCELAR') }}
          </b-button>
          <b-button variant="primary" @click="salvar">
            {{ $t('GERAL.SALVAR') }}
          </b-button>
        </div>
      </template>

      <template v-if="!formularioHistorico">
        <div class="d-flex w-100 justify-content-between">
          <b-button class="mr-2" variant="secondary" @click="fecharModal">
            {{ $t('GERAL.FECHAR') }}
          </b-button>
          <b-button variant="info" @click="abrirFormulario">
            {{ $t('GERAL.CADASTRAR') }}
          </b-button>
        </div>
      </template>
    </template>
  </modal>
</template>
<script>
// Utils & Auxs:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import pdf from '@/common/utils/pdf';

// Services:
import CurriculoEscolarService from '@/common/services/curriculo-escolar/curriculo-escolar.service';

// Components:
import Modal from '@/components/modal/Modal';
import FormHistorico from './Form';
import Disciplinas from './disciplinas/Index';
import Tabela from './Tabela';
export default {
  props: {
    exibir: { type: Boolean, default: false },
    aluno: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormHistorico,
    Disciplinas,
    Tabela,
  },
  data() {
    return {
      refs: [],
      opcoes: {
        instituicoes: [],
      },
      auxiliares: {
        cadastrar: false,
      },
      historico: {
        disciplinas: [],
      },
      historicos: [],
    };
  },
  watch: {
    exibir: 'buscarHistorico',
  },
  computed: {
    formularioHistorico() {
      return this.auxiliares.cadastrar;
    },
    titulo() {
      return !this.formularioHistorico
        ? `Históricos escolares de ${this.aluno.nome}`
        : `Cadastrar histórico escolar para ${this.aluno.nome}`;
    },
  },
  methods: {
    // FUNÇÕES OBTER DADOS:
    buscarHistorico() {
      this.$store.dispatch(START_LOADING);
      CurriculoEscolarService.obterPorMatriculaId(this.aluno.id)
        .then(({ data }) => {
          this.historicos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    imprimirHistorico() {
      this.$store.dispatch(START_LOADING);
      CurriculoEscolarService.imprimirHistorico(this.aluno.id)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], {
              type: 'application/pdf',
            });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    salvar() {
      this.historico.id ? this.editar() : this.criar();
    },
    criar() {
      this.historico.matriculaId = this.aluno.id;
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      CurriculoEscolarService.criar(this.historico)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Histórico cadastrado!');
          this.buscarHistorico();
          this.voltar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const novosDados = {
        ...this.historico,
        disciplinas: this.historico.disciplinas.map((d) => ({
          ...d,
          cargaHoraria: d.cargaHoraria ?? 0,
          faltas: d.faltas ?? 0,
          media: d.media ?? 0,
        })),
      };
      CurriculoEscolarService.editar(novosDados)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Histórico cadastrado!');
          this.buscarHistorico();
          this.voltar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        'Confirme a exclusão do histórico escolar',
        'Deseja excluir este histórico escolar?'
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item);
      }
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      CurriculoEscolarService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Histórico excluído!');
          this.buscarHistorico();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.voltar();
      this.$emit('fechar');
    },
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      const cargaHorariaDisciplinas = this.historico.disciplinas.reduce(
        (total, disciplina) => total + parseFloat(disciplina.cargaHoraria),
        0
      );
      if (cargaHorariaDisciplinas > this.historico.cargaHoraria) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          'A soma das cargas horárias das disciplinas ultrapassa a carga horária do ano letivo.'
        );
        return;
      }
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES AUXILIARES:
    abrirFormulario(item) {
      item.id
        ? (this.historico = item)
        : (this.historico = { disciplinas: [] });
      this.auxiliares.cadastrar = true;
    },
    voltar() {
      this.auxiliares.cadastrar = false;
    },
  },
};
</script>
