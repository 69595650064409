<template>
  <b-table
    :items="items"
    :fields="fields"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    striped
    responsive
    hover
  >
    <template #cell(disciplinaCurriculo)="item">
      <p>{{ nomeDisciplina(item) }}</p>
    </template>
    <!-- SE FOR UMA EDIÇÃO, VAI ABRIR OS CAMPOS PARA EDIÇÃO -->
    <template v-if="editar.exibir" #cell(disciplinaCurriculoId)="item">
      <input-select-search
        ref="disciplinaCurriculoId"
        :options="disciplinas"
        v-model="item.item.disciplinaCurriculoId"
        required
      />
    </template>
    <template v-if="editar.exibir" #cell(faltas)="item">
      <input-number
        ref="faltas"
        v-model="item.item.faltas"
        removeSpecialChars
        removeWhiteSpaces
        required
      />
    </template>
    <template v-if="editar.exibir" #cell(cargaHoraria)="item">
      <input-number
        ref="cargaHoraria"
        v-model="item.item.cargaHoraria"
        required
      />
    </template>
    <template v-if="editar.exibir" #cell(media)="item">
      <input-number ref="media" v-model="item.item.media" required />
    </template>
    <template #cell(acoes)="item">
      <template v-if="editar.exibir">
        <b-button variant="primary" @click="atualizar(item.item)">
          {{ $t('GERAL.ATUALIZAR') }}
        </b-button>
      </template>
      <template v-else>
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="habilitarEdicao(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="confirmarExclusao(item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import CurriculoEscolarService from '@/common/services/curriculo-escolar/curriculo-escolar.service';

// Components:
import { InputNumber, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    items: { type: Array, default: Array },
    curriculoEscolarId: { type: String, default: String },
    disciplinas: { type: Array, default: Array },
  },
  components: {
    InputNumber,
    InputSelectSearch,
  },
  data() {
    return {
      fields: [
        {
          key: 'disciplinaCurriculo',
          label: 'Disciplina',
          sortable: true,
        },
        {
          key: 'faltas',
          label: 'Faltas',
          sortable: true,
        },
        {
          key: 'cargaHoraria',
          label: 'Carga Horária',
          sortable: true,
        },
        {
          key: 'media',
          label: 'Média',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
      editar: {
        exibir: false,
        disciplinas: [],
      },
    };
  },
  methods: {
    nomeDisciplina(item) {
      return item.item.id
        ? item.item.disciplinaCurriculo.descricao
        : this.procuraDescricao(item);
    },
    procuraDescricao(item) {
      for (var i = 0; i < this.disciplinas.length; i++) {
        if (this.disciplinas[i]['id'] == item.item.disciplinaCurriculoId) {
          return this.disciplinas[i]['descricao'];
        }
      }
      return 'Erro ao buscar nome da disciplina.';
    },
    // FUNÇÕES CRUD:
    habilitarEdicao() {
      this.editar.exibir = true;
    },
    fecharEdicao() {
      this.editar.exibir = false;
    },
    atualizar(item) {
      if (item.id) {
        item.entidadeId = this.curriculoEscolarId;
        this.$store.dispatch(START_LOADING);
        CurriculoEscolarService.atualizarDisciplina(item)
          .then(() => {
            mensagem.sucesso(
              this.$t('GERAL.SUCESSO'),
              'Disciplina atualizada!'
            );
            this.fecharEdicao();
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      } else {
        this.fecharEdicao();
      }
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.CONFIRMAR_EXCLUIR_DISCIPLINA'),
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      if (!item.item.id) return this.items.splice(item.index, 1);

      this.$store.dispatch(START_LOADING);
      CurriculoEscolarService.excluirDisciplina(item.item)
        .then(() => {
          this.items.splice(item.index, 1);
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Disciplina excluída!');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
