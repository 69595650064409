<template>
  <div class="row">
    <div class="col-12">
      <input-select-search
        ref="instituicaoId"
        :label="$t(`INSTITUICAO_ENSINO.INSTITUICAO_ENSINO`)"
        :options="opcoes.instituicoes"
        v-model="form.instituicaoEnsinoId"
        required
      />
    </div>
    <div class="col-12 col-md-3">
      <input-select-search
        ref="periodoLetivo"
        :label="'Ano Letivo'"
        :options="opcoes.anosLetivos"
        v-model="form.anoLetivoId"
        required
      />
    </div>
    <div class="col-12 col-md-3">
      <input-select-search
        ref="turmas"
        :label="'Ano/Série'"
        :options="opcoes.turmas"
        v-model="form.turmaCurriculoId"
        required
      />
    </div>
    <div class="col-12 col-md-2">
      <input-text
        ref="cargaHoraria"
        v-model="form.cargaHoraria"
        :label="'Carga Horária'"
        type="number"
        required
      />
    </div>
    <div class="col-12 col-md-2">
      <input-text
        ref="frequencia"
        v-model="form.frequencia"
        :label="'Frequência'"
        type="number"
        required
      />
    </div>
    <div class="col-12 col-md-2">
      <input-select
        ref="status"
        :label="'Status'"
        :options="opcoes.status"
        v-model="form.statusBoletim"
        required
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino.js';
import TurmaCurriculo from '@/common/services/curriculo-escolar/turma.service';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';

// Components:
import { InputText, InputSelectSearch, InputSelect } from '@/components/inputs';
export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputSelect,
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        instituicoes: [],
        turmas: [],
        anosLetivos: [],
        status: [
          {
            text: 'Aprovado',
            value: 'Aprovado',
          },
          {
            text: 'Reprovado',
            value: 'Reprovado',
          },
        ],
      },
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getInstituicoes();
    this.getTurmas();
    this.getPeriodoLetivo();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getInstituicoes() {
      let filtro = '';
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.obterPorFiltro(filtro)
        .then(({ data }) => {
          data.itens = data.itens.map((row) => ({
            ...row,
            text: row.nome,
            value: row.id,
          }));
          this.opcoes.instituicoes = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTurmas() {
      this.$store.dispatch(START_LOADING);
      TurmaCurriculo.listarTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getPeriodoLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          data.forEach((element) => {
            element.text = element.ano;
            element.value = element.id;
          });
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
