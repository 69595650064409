<template>
  <!--
        EXEMPLO DE USO DO COMPONENTE:
        <div class="col-12">
            <filtro-matricula-nome @resultado="obterMatriculaNome" :limpar="limparFiltro" />
        </div>
    -->

  <b-row>
    <b-col md="4">
      <input-select-search
        v-model="matriculaSelecionada"
        :options="matriculas"
        label="FINANCEIRO.BUSCAR_NOME_CODIGO_MATRICULA"
        placeholder="GERAL.DIGITE_OU_PROCURE"
      />
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service.js';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    InputSelectSearch,
  },
  mounted() {
    this.listarMatriculasSemPaginacao();
  },
  data() {
    return {
      matriculaSelecionada: '',
      matriculas: [],
    };
  },
  watch: {
    limpar: 'limparFiltro',
    matriculaSelecionada() {
      this.$emit('resultado', this.matriculaSelecionada);
    },
  },
  methods: {
    limparFiltro() {
      this.matriculaSelecionada = '';
    },
    listarMatriculasSemPaginacao() {
      this.$store.dispatch(START_LOADING);
      MatriculaService.listarSemPaginacao()
        .then(({ data }) => {
          if (data.length) {
            data = data.map((row) => {
              return {
                ...row,
                text: `${row.codigoMatricula} - ${row.nome}`,
                value: row.id,
              };
            });

            this.matriculas = data;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
