<template>
  <div class="mt-2">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h6>{{ $t('ACADEMICO.DISCIPLINA_CADASTRAR') }}</h6>
      <b-btn variant="info" @click="mostrarFormulario">
        {{ exibirFormulario ?  $t('GERAL.ESCONDER_FORMULARIO') : $t('GERAL.EXIBIR_FORMULARIO') }}
      </b-btn>
    </div>
    <form-disciplina
      v-if="exibirFormulario"
      :form="form"
      :disciplinas="opcoes.disciplinas"
      @adicionar="adicionar"
    />
    <tabela
      :curriculoEscolarId="form.id"
      :items="form.disciplinas"
      :disciplinas="opcoes.disciplinas"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import CurriculoEscolarService from '@/common/services/curriculo-escolar/curriculo-escolar.service';
import DisciplinaService from '@/common/services/curriculo-escolar/disciplina.service';

// Components:
import FormDisciplina from './Form';
import Tabela from './Tabela';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    FormDisciplina,
    Tabela,
  },
  mounted() {
    this.getDisciplinas();
  },
  data() {
    return {
      exibirFormulario: false,
      opcoes: {
        disciplinas: [],
      },
    };
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getDisciplinas() {
      this.$store.dispatch(START_LOADING);
      DisciplinaService.obterTodas()
        .then(({ data }) => {
          this.opcoes.disciplinas = data.itens;
          this.opcoes.disciplinas = this.opcoes.disciplinas.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    adicionar(disciplina) {
      if (!this.form.id) {
        this.form.disciplinas.push(disciplina);
      } else {
        disciplina.entidadeId = this.form.id;

        this.$store.dispatch(START_LOADING);
        CurriculoEscolarService.adicionarDisciplina(disciplina)
          .then(() => {
            mensagem.sucesso(
              this.$t('GERAL.SUCESSO'),
              'Disciplina adicionada!'
            );
            this.form.disciplinas.push(disciplina);
            this.exibirFormulario = false;
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },

    mostrarFormulario() {
      this.exibirFormulario = !this.exibirFormulario;
    },
  },
};
</script>
