<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    show-empty
    responsive
    striped
    hover
  >
    <template #cell(acoes)="item">
      <div class="flex">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="editar(item.item)">
            Editar histórico
          </b-dropdown-item>
          <b-dropdown-item @click="excluir(item.item)">
            Excluir histórico
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'anoLetivo.ano',
          label: 'Período Letivo',
          sortable: true,
        },
        {
          key: 'instituicaoEnsino.nome',
          label: 'Instituição de Ensino',
          sortable: true,
        },
        {
          key: 'turmaCurriculo.descricao',
          label: 'Anos/Séries',
          sortable: true,
        },
        {
          key: 'cargaHoraria',
          label: 'Carga horária',
          sortable: true,
        },
        {
          key: 'frequencia',
          label: 'Frequência',
          sortable: true,
        },
        {
          key: 'statusBoletim',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>
