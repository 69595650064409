<template>
  <div>
    <b-table
      :items="items"
      :fields="campos"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="verHistorico(item.item)">
            Ver histórico
          </b-dropdown-item>
          <b-dropdown-item @click="abrirModalImprimirCertificado(item.item)">
            {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_CERTIFICADO') }}
          </b-dropdown-item>
          <b-dropdown-item @click="abrirModalImprimirHistorico(item.item)">
            {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_HISTORICO_ESCOLAR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <modal-imprimir-historico
      :exibir="modais.exibirImprimirHistorico"
      :item="modais.historicoDadosAluno"
      @fechar="fecharModalImprimirHistorico"
    />
    <modal-imprimir-certificado
      :exibir="modais.exibirImprimirCertificado"
      :item="modais.historicoDadosAluno"
      @fechar="fecharModalImprimirCertificado"
    />
  </div>
</template>
<script>
//components
import ModalImprimirHistorico from '@/views/academico/matriculas/components/modais/ModalImprimirHistorico.vue';
import ModalImprimirCertificado from '@/views/academico/matriculas/components/modais/ModalImprimirCertificado.vue';
export default {
  components: {
    ModalImprimirHistorico,
    ModalImprimirCertificado,
  },
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'codigoMatricula',
          label: this.$t('ACADEMICO_MATRICULAS.CODIGO_MATRICULA'),
        },
        {
          key: 'nome',
          label: this.$t('ACADEMICO_MATRICULAS.ALUNO'),
        },
        {
          key: 'idiomaDescricao',
          label: this.$t('ACADEMICO_MATRICULAS.IDIOMA'),
        },
        {
          key: 'status',
          label: this.$t('GERAL.STATUS'),
        },
        {
          key: 'observacao',
          label: this.$t('GERAL.OBSERVACAO'),
          formatter: (value) => {
            return value ? `${value?.substring(0, 25)}...` : '-';
          },
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
      modais: {
        exibirImprimirHistorico: false,
        exibirImprimirCertificado: false,
        historicoDadosAluno: {},
      },
    };
  },
  methods: {
    verHistorico(item) {
      this.$emit('historico', 'historico', item);
    },

    abrirModalImprimirHistorico(item) {
      this.modais.exibirImprimirHistorico = true;
      this.modais.historicoDadosAluno = item;
    },

    fecharModalImprimirHistorico() {
      this.modais.exibirImprimirHistorico = false;
      this.modais.historicoDadosAluno = {};
    },

    abrirModalImprimirCertificado(item) {
      this.modais.exibirImprimirCertificado = true;
      this.modais.historicoDadosAluno = item;
    },

    fecharModalImprimirCertificado() {
      this.modais.exibirImprimirCertificado = false;
      this.modais.historicoDadosAluno = {};
    },
  },
};
</script>
